.LinkedinButton {
    background-color: #2867b2;
    border: none;
    border-radius: 1rem;
    box-shadow: .1rem .1rem 5px #2866b29f ;
    padding: .1rem 2rem;
    width: 18rem;
}

.NotLinkedinButton {
    background-color: #494f57;
    border: none;
    border-radius: 1rem;
    box-shadow: .1rem .1rem 5px #494f57c5 ;
    padding: .1rem 2rem;
    width: 18rem;
}

.LinkedinButton:hover {
    background-color: #30a5f8;
    cursor: pointer;
    transition: 100ms;
}

.NotLinkedinButton:hover + .hide {
    display: block;
    color: red;
}

.LinkedinButton .container{
    display: grid;
    grid-template-columns: 2.5rem 11.5rem;
    grid-template-rows: 1fr;
}

.NotLinkedinButton .container{
    display: grid;
    grid-template-columns: 2.5rem 11.5rem;
    grid-template-rows: 1fr;
}

.LinkedinButton .container p {
    grid-column: 2;
    grid-row: 1;

    margin: .8rem;

    color: white;
    font-size: 1.4rem;
    font-weight: 500;

    align-self: center;
    justify-self: left;
    padding-left: .6rem;
}

.NotLinkedinButton .container p {
    grid-column: 2;
    grid-row: 1;

    margin: .8rem;

    color: white;
    font-size: 1.4rem;
    font-weight: 500;

    align-self: center;
    justify-self: left;
    padding-left: .6rem;
}

.LinkedinButton .container img {
    grid-column: 1;
    grid-row: 1;
    width: 2.5rem;

    align-self: center;
    justify-self: center;
}

.NotLinkedinButton .container img {
    grid-column: 1;
    grid-row: 1;
    width: 2.5rem;

    align-self: center;
    justify-self: center;
}