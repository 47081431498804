.CandidateInfo {
    max-width: 18rem !important;
    margin: .6rem;
}

.CandidateInfo h1{
    margin: .3rem;
}

.CandidateInfo h3{
    margin: .3rem;
    font-weight: 600;
}

.CandidateInfo h4{
    margin: .3rem;
}

 .CandidateInfo ul{
    margin: .3rem;
    padding-left: 2rem;
    color: rgb(116, 116, 116);
}

 .CandidateInfo ul li{
    color: rgb(116, 116, 116);
}

