.EmailButton {
    background-color: #eccd00;
    border: none;
    border-radius: 1rem;
    box-shadow: .1rem .1rem 5px #c3aa04 ;
    padding: .1rem 2rem;
    width: 18rem;
}

.RemoveButton {
    background-color: #f11717;
    border: none;
    border-radius: 1rem;
    box-shadow: .1rem .1rem 5px #f11717a8 ;
    padding: .1rem 2rem;
    min-width: 18rem;

}

.EmailButton:hover {
    background-color: #ffe643;
    cursor: pointer;
    transition: 100ms;
}

.RemoveButton:hover {
    background-color: #f03a3a;
    cursor: pointer;
    transition: 100ms;
}

.EmailButton .container{
    display: grid;
    grid-template-columns: 2.5rem 11.5rem;
    grid-template-rows: 1fr;
}

.RemoveButton .container{
    display: grid;
    grid-template-columns: 2.5rem 9.5rem;
    grid-template-rows: 1fr;

}

.EmailButton .container p {
    grid-column: 2;
    grid-row: 1;

    margin: .8rem;

    color: white;
    font-size: 1.4rem;
    font-weight: 500;

    align-self: center;
    justify-self: left;
    padding-left: .6rem;
}

.RemoveButton .container p {
    grid-column: 2;
    grid-row: 1;

    margin: .8rem;

    color: white;
    font-size: 1.4rem;
    font-weight: 500;

    align-self: center;
    justify-self: left;
    padding-left: .6rem;
}

.EmailButton .container img {
    grid-column: 1;
    grid-row: 1;
    width: 2.5rem;

    align-self: center;
    justify-self: center;
}

.RemoveButton .container img {
    grid-column: 1;
    grid-row: 1;
    width: 2.5rem;

    align-self: center;
    justify-self: center;
}