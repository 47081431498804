.Main {
    margin-top: auto;
    margin-bottom: auto;
    display: grid;
    background-color: white;

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    grid-template-columns: 1fr;
    grid-template-rows: auto repeat(3, 1fr) auto;
    align-items: center;
    justify-items: center;

    padding: 2rem;
    border-radius: 1rem;
}

.Main .CandidateInfo {
    justify-self: left;
    grid-row: 1;
    grid-column: 0;
}

.Main .WhatsappButton {
    margin: .6rem;
    grid-row: 2;
    grid-column: 0;
}

.Main .Linkedin {
    display: flex;
    margin: .6rem;
    grid-row: 3;
    grid-column: 0;
} 

.Main .NotFound {
    margin: .6rem;
    grid-row: 3;
    grid-column: 0;
} 

.Main .Save {
    margin: .6rem;
    grid-row: 4;
    grid-column: 0;
}

.Main .NavButtons {
    margin-top: 1rem;
    grid-row: 5;
    grid-column: 0;
}