.WhatsappButton {
    background-color: #128C7E;
    border: none;
    border-radius: 1rem;
    box-shadow: .1rem .1rem 5px #128c7eb9 ;
    padding: .1rem 2rem;
    max-width: 18rem;
}

.NotWhatsappButton {
    background-color: #494f57;
    border: none;
    border-radius: 1rem;
    box-shadow: .1rem .1rem 5px #494f57c5 ;
    padding: .1rem 2rem;
    max-width: 18rem;
}

.WhatsappButton:hover {
    background-color: #25D366;
    cursor: pointer;
    transition: 100ms;
}

.NotWhatsappButton:hover + .hide {
    display: block;
    color: red;
}

.WhatsappButton .container{
    display: grid;
    grid-template-columns: 2.5rem 11.5rem;
    grid-template-rows: 1fr;
}

.WhatsappButton .container p {
    grid-column: 2;
    grid-row: 1;

    margin: .8rem;

    color: white;
    font-size: 1.4rem;
    font-weight: 500;

    align-self: center;
    justify-self: left;
    padding-left: .6rem;
}

.WhatsappButton .container img {
    grid-column: 1;
    grid-row: 1;
    width: 2.5rem;

    align-self: center;
    justify-self: center;
}

.NotWhatsappButton .container{
    display: grid;
    grid-template-columns:2.5rem 11.5rem;
    grid-template-rows: 1fr;
}
.NotWhatsappButton .container p {
    grid-column: 2;
    grid-row: 1;

    margin: .8rem;

    color: white;
    font-size: 1.4rem;
    font-weight: 500;

    align-self: center;
    justify-self: left;
    padding-left: .6rem;
}

.NotWhatsappButton .container img {
    grid-column: 1;
    grid-row: 1;
    width: 2.5rem;

    align-self: center;
    justify-self: center;
}