.NavButtons button {
    width: 4.5rem;
    margin: 0rem 1rem;
    background-color: #dee1e4;
    border: none;
    border-radius: 1rem;
    box-shadow: .1rem .1rem 5px #bdc1c783 ;
    padding: .5rem;
}

.NavButtons button:hover {
    background-color: #d1d7df;
    cursor: pointer;
    transition: 100ms;
}